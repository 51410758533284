import React from "react";
import { Layout, Space } from "antd";
const { Content } = Layout;
import { useNavigate } from "react-router-dom";
import { Header, SoldProductsIndex } from "@components";

export const Sold = () => {
  return (
    <Space
      direction="vertical"
      style={{ width: "100%", backgroundColor: "white" }}
      size={[0, 48]}
    >
      <Content>
        <Header selected="sold_items" />
        <SoldProductsIndex />
      </Content>
    </Space>
  );
};
