import React from "react";
import { Layout, Space } from "antd";
const { Content } = Layout;
import { isSuperAdmin } from "@utils";
import { Header, PayoutsIndex } from "@components";

export const Payouts = () => {
  return isSuperAdmin() ? (
    <Space
      direction="vertical"
      style={{ width: "100%", backgroundColor: "white" }}
      size={[0, 48]}
    >
      <Content>
        <Header selected="payouts" />
        <PayoutsIndex />
      </Content>
    </Space>
  ) : null;
};
