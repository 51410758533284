import React, { useEffect, useState } from "react";
import {
  getApi,
  moneyFormat,
  isSuperAdmin,
  readValue,
  writeValue,
  useWindowSize,
} from "@utils";
import { Table, Space, Input, Select } from "antd";
import { IShopifyProductType, IConfigType } from "@types";
import { NavButton, ActionButton } from "@components";
import moment from "moment";
const inflection = require("inflection");
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { Typography } from "antd";
const { Title } = Typography;

interface TableParams {
  pagination?: TablePaginationConfig;
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const Row = ({ children, ...props }: RowProps) => {
  return (
    <tr {...props}>
      {React.Children.map(children, (child) => {
        return child;
      })}
    </tr>
  );
};

export const SoldProductsIndex = (props) => {
  const { width } = useWindowSize();
  const [data, setData] = useState<IShopifyProductType[]>([]);
  const [config, setConfig] = useState<IConfigType>();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [prevPage, setPrevPage] = useState("");
  const [nextPage, setNextPage] = useState("");
  const [page, setPage] = useState("");
  const [showSold, setShowSold] = useState(false);
  const [search, setSearch] = useState(readValue("searchQuery"));
  const [consignor, setConsignor] = useState(readValue("searchConsignor"));

  const onPrevPage = () => {
    setPage(prevPage);
  };

  const onNextPage = () => {
    setPage(nextPage);
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        // @ts-ignore
        <a onClick={onPrevPage}>Previous</a>
      );
    }
    if (type === "next") {
      return (
        // @ts-ignore
        <a onClick={onNextPage}>Next</a>
      );
    }
  };

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      position: ["bottomCenter"],
      current: 1,
      pageSize: 25,
      total,
      itemRender,
    },
  });

  const fetchData = async () => {
    setLoading(true);
    const response = await getApi("getProducts", {
      page: tableParams.pagination.current,
      per_page: tableParams.pagination.pageSize,
      sold: "true",
      search,
      consignor,
      ...(window.location.search.includes("needs_payouts")
        ? { needs_payouts: true }
        : {}),
    });
    setLoading(false);
    if (response?.data) {
      setData(response.data);
      setTotal(response.count);
    }
    if (isSuperAdmin()) {
      const configResponse = await getApi("getConfig", {});
      if (response?.data) {
        setConfig(configResponse.data);
      }
    }
  };

  useEffect(() => {
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total,
        itemRender,
      },
    });
  }, [total]);
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(tableParams), search, showSold, consignor]);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setTableParams({
      pagination,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const columns: ColumnsType<IShopifyProductType> = [
    {
      title: "Photo",
      dataIndex: "photo",
      render: (photo, record) => {
        return (
          <a
            href={`https://justinreed.com/products/${record.handle}`}
            target="_blank"
          >
            <img src={photo} width={150} style={{ backgroundColor: "#000" }} />
          </a>
        );
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      sorter: false,
      render: (title, record) => {
        return (
          <a
            href={
              isSuperAdmin()
                ? `https://admin.shopify.com/store/justinreednewyork/products/${record.shopify_id}`
                : `https://justinreed.com/products/${record.handle}`
            }
            target="_blank"
          >
            {title}
          </a>
        );
      },
    },
    {
      title: "Sizes",
      dataIndex: "size",
      sorter: false,
      render: (size) => {
        return size == "Default Title" ? "OS" : size;
      },
    },
    ...(isSuperAdmin()
      ? [
          {
            title: "Consignor",
            dataIndex: "consignor",
            sorter: false,
          },
        ]
      : []),
    {
      title: "Brand",
      dataIndex: "vendor",
      sorter: false,
    },
    {
      title: "Listing Price",
      dataIndex: "price",
      sorter: false,
      render: (_, record) => {
        return record.payout
          ? moneyFormat(record.payout.listing_price)
          : moneyFormat(record.price);
      },
    },
    {
      title: "Sold Price",
      dataIndex: "sold_price",
      sorter: false,
      render: (_, record) => {
        return record.payout ? moneyFormat(record.payout.price_paid) : "---";
      },
    },
    {
      title: "Sold At",
      dataIndex: "sold_at",
      sorter: false,
      render: (_, record) => {
        return record?.payout?.sold_at
          ? moment.unix(record?.payout?.sold_at).calendar()
          : "---";
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: false,
      render: (_, record) => {
        return record.is_returned
          ? "Returned"
          : record?.payout?.status === "confirmed"
          ? record?.payout?.payable_at > Math.floor(Date.now() / 1000)
            ? "Processing"
            : record?.payout?.historic_sale
            ? "Paid"
            : inflection.titleize(
                record.payout?.status == "confirmed"
                  ? "Paid"
                  : record.payout?.status || ""
              )
          : inflection.titleize(record.payout?.status || "");
      },
    },
    // {
    //   title: "Earnings",
    //   dataIndex: "earning_amount",
    //   sorter: false,
    //   render: (_, record) => {
    //     return !record.payout?.historic_sale && record?.payout?.earning_amount
    //       ? moneyFormat(record.payout.earning_amount)
    //       : "---";
    //   },
    // },
    // {
    //   title: "Earnings After Cash Out (2.9% Fee)",
    //   dataIndex: "cashout_amount",
    //   sorter: false,
    //   render: (_, record) => {
    //     return !record.payout?.historic_sale && record?.payout?.cashout_amount
    //       ? moneyFormat(record.payout.cashout_amount)
    //       : "---";
    //   },
    // },
    // {
    //   title: "Request Payment",
    //   dataIndex: "payable",
    //   sorter: false,
    //   render: (_, record) => {
    //     return record?.payout?.payable ? (
    //       <Space>
    //         <ActionButton
    //           endpoint={"redeemPayout"}
    //           method={"PUT"}
    //           text={"Request Store Credit"}
    //           primary={true}
    //           pathParams={{ id: record.payout?.id }}
    //           body={{ redeem_type: "store_credit" }}
    //           onComplete={() => {
    //             location.reload();
    //           }}
    //         />
    //         <ActionButton
    //           endpoint={"redeemPayout"}
    //           method={"PUT"}
    //           text={`Request Payout (${moneyFormat(
    //             record.payout.cashout_amount
    //           )})`}
    //           primary={true}
    //           pathParams={{ id: record.payout?.id }}
    //           body={{ redeem_type: "payout" }}
    //           onComplete={() => {
    //             location.reload();
    //           }}
    //         />
    //       </Space>
    //     ) : (
    //       record?.payout?.redeem_type &&
    //         `${inflection.titleize(record?.payout?.redeem_type)}${
    //           record?.payout?.paid_at
    //             ? `Note from JR Team: ${record?.payout?.consignor_notes}`
    //             : ""
    //         }`
    //     );
    //   },
    // },
  ];

  if (isSuperAdmin()) {
    columns.push({
      title: "Action",
      dataIndex: "action",
      sorter: false,
      render: (_, record) => {
        return (
          <>
            {!record?.payout && (
              <Space>
                <NavButton
                  text={"Create Payout"}
                  path={`/payouts/new/${record.id}`}
                />
              </Space>
            )}
            {record.is_returned && (
              <ActionButton
                endpoint={"putProductMarkReturned"}
                method={"PUT"}
                text={"Unmark as Returned"}
                pathParams={{}}
                body={{ product_id: record?.id }}
                onComplete={() => {
                  location.reload();
                }}
              />
            )}
          </>
        );
      },
    });
    columns.push({
      title: "ID",
      dataIndex: "id",
      sorter: false,
      render: (id) => {
        return id;
      },
    });
  }

  return (
    <Space
      size="middle"
      direction="vertical"
      style={{ padding: "0 15px", width: "calc(100% - 32px)" }}
    >
      <Input
        size="large"
        defaultValue={search}
        allowClear={true}
        placeholder="Search Your Consignments"
        onChange={(e) => {
          writeValue("searchQuery", e.target.value);
          setSearch(e.target.value);
        }}
        onPressEnter={() => {
          writeValue("searchQuery", search);
          fetchData();
        }}
      />
      <div>
        {!!total && (
          <Title level={5} style={{ marginTop: 5 }}>
            {total === 1
              ? `1 Result`
              : `${total.toLocaleString("en-US")} Results`}
          </Title>
        )}
        {isSuperAdmin() && config?.sold_consignors && (
          <Select
            showSearch={true}
            allowClear={true}
            style={{ width: 240 }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label?.toLowerCase() ?? "").includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            defaultValue={consignor}
            onChange={(value) => {
              if (typeof value === "string") {
                writeValue("searchConsignor", value);
                setConsignor(value);
              } else {
                writeValue("searchConsignor", "");
                setConsignor("");
              }
            }}
            options={Object.entries(config.sold_consignors).map(
              ([consignor, count]) => ({
                label: `${consignor} (${count})`,
                value: consignor,
              })
            )}
          />
        )}
      </div>
      <div
        style={{
          maxWidth: width > 768 ? "auto" : document.documentElement.clientWidth,
          overflow: "auto",
        }}
      >
        <Table
          columns={columns}
          rowKey={(record) => record.id}
          expandable={{
            defaultExpandedRowKeys: ["0"],
            defaultExpandAllRows: true,
            expandRowByClick: true,
          }}
          locale={{
            emptyText: "No Sold Items",
          }}
          components={{
            body: {
              row: Row,
            },
          }}
          dataSource={data}
          pagination={tableParams.pagination}
          loading={loading}
          onChange={handleTableChange}
        />
      </div>
    </Space>
  );
};
