import React, { FunctionComponent, useState } from "react";
import { putApi, postApi, deleteApi, CEndpoints } from "@utils";
import { TMethod } from "@types";
import { Popconfirm, Button } from "antd";
import { useNavigate } from "react-router-dom";

interface IActionButtonProps {
  method: TMethod;
  endpoint: keyof typeof CEndpoints;
  text: string;
  primary?: boolean;
  body: Record<any, any>;
  pathParams: Record<any, any>;
  onComplete?: (payload?: Record<any, any>) => void;
}

export const ActionButton: FunctionComponent<IActionButtonProps> = ({
  method,
  endpoint,
  text,
  body,
  pathParams,
  onComplete,
  primary,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const onClick = async () => {
    setLoading(true);
    if (method === "POST") {
      const response = await postApi(endpoint, body, pathParams);
      onComplete && onComplete(response.data);
      navigate(response.redirect);
    }
    if (method === "PUT") {
      const response = await putApi(endpoint, body, pathParams);
      onComplete && onComplete(response.data);
      navigate(response.redirect);
    }
    if (method === "DELETE") {
      const response = await deleteApi(endpoint, pathParams);
      onComplete && onComplete(response.data);
      navigate(response.redirect);
    }
    setLoading(false);
  };
  return (
    <Popconfirm
      title="Confirm"
      okText="Yes"
      cancelText="No"
      onConfirm={onClick}
    >
      <Button
        danger={method == "DELETE"}
        type={primary ? "primary" : "default"}
        disabled={loading}
      >
        {text}
      </Button>
    </Popconfirm>
  );
};
