import React from "react";
import { Layout, Space } from "antd";
const { Content } = Layout;
import { Header, PayoutsForm } from "@components";
import { useParams } from "react-router-dom";

export const PayoutsNew = () => {
  const { id, shopify_product_id } = useParams();
  return (
    <Space
      direction="vertical"
      style={{ width: "100%", backgroundColor: "white" }}
      size={[0, 48]}
    >
      <Content>
        <Header selected="payouts" />
        <PayoutsForm id={id} shopify_product_id={shopify_product_id} />
      </Content>
    </Space>
  );
};
