import React, { FunctionComponent, useEffect, useState } from "react";
import type { MenuProps } from "antd";
import { getApi, isSuperAdmin, useWindowSize } from "@utils";
import { IUserType } from "@types";
import {
  DollarOutlined,
  SkinOutlined,
  DownOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";
import { Menu, Button, Popover, Layout, Typography, Tag } from "antd";
import { useNavigate } from "react-router-dom";
const { Header: FrameHeader } = Layout;
import { Row } from "antd";
const { Text, Link } = Typography;

import { useAuth0 } from "@auth0/auth0-react";

interface IHeaderProps {
  selected: string;
}

const ROUTE_MAP = {
  listed_items: "/",
  sold_items: "/sold",
  payouts: "/payouts",
};

export const Header: FunctionComponent = ({ selected }: IHeaderProps) => {
  const { width } = useWindowSize();
  const { logout, user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const [data, setData] = useState<IUserType>();
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const items: MenuProps["items"] = [
    {
      label: "Listed Items",
      key: "listed_items",
      icon: <SkinOutlined />,
    },
    {
      label: "Sold Items",
      key: "sold_items",
      icon: <DollarOutlined />,
    },
  ];

  if (isSuperAdmin()) {
    items.push({
      label: "Payouts",
      key: "payouts",
      icon: <ReconciliationOutlined />,
    });
  }

  const [current, setCurrent] = useState(selected || "listed_items");

  const onClick: MenuProps["onClick"] = (e) => {
    setCurrent(e.key);
    navigate(ROUTE_MAP[e.key]);
  };

  const fetchData = async () => {
    setLoading(true);
    const response = await getApi("getUsers", {});
    setLoading(false);
    if (response?.data) {
      setData(response.data);
      setReady(true);
    }
  };
  useEffect(() => {
    setData(null);
    setReady(false);
    if (isAuthenticated) {
      fetchData();
    }
  }, [user, isAuthenticated]);

  const content = (
    <div>
      {data ? (
        <>
          {data.first_name || data.last_name ? (
            <p>{[data.first_name, data.last_name].join(" ")}</p>
          ) : null}
        </>
      ) : null}
      {data ? (
        <>
          {data.address_line_1 || data.address_line_2 ? (
            <p>
              {[data.address_line_1, data.address_line_2].join(", ")}
              {
                <>
                  <br />
                  <Text>{`${data.city}, ${data.state} ${data.zip_code}`}</Text>
                </>
              }
            </p>
          ) : null}
        </>
      ) : null}
      {data ? (
        <>
          {data.phone_number ? <p>{<Text>{data.phone_number}</Text>}</p> : null}
        </>
      ) : null}
      <Button
        type="link"
        onClick={() => {
          navigate("/edit-profile");
        }}
      >
        Edit Profile
      </Button>
    </div>
  );

  return (
    <FrameHeader
      style={{
        position: "sticky",
        top: 0,
        zIndex: 1,
        width: "calc(100%)",
        display: "flex",
        alignItems: "center",
        background: "white",
        padding: "0 15px",
        height: width > 768 ? 64 : 180,
      }}
    >
      <Row
        justify="space-between"
        align="middle"
        gutter={[16, 16]}
        style={{ width: "100%", marginLeft: 0, marginRight: 0 }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: width > 768 ? "row" : "column",
            alignItems: "center",
          }}
        >
          <img
            src={"https://jrnysell.s3.amazonaws.com/JustinReedType.png"}
            height={20}
            style={{
              marginRight: width > 768 ? 16 : 0,
              marginTop: width > 768 ? 0 : 20,
            }}
          />
          <Menu
            onClick={onClick}
            selectedKeys={[current]}
            mode="horizontal"
            items={items}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {ready && (
            <Popover content={content} title="Profile" trigger="click">
              <div style={{ marginRight: 16 }}>
                {ready && (
                  <Text>
                    {data
                      ? data.first_name
                        ? [data.first_name, data.last_name].join(" ")
                        : data.email
                      : ""}
                  </Text>
                )}
                {isSuperAdmin() && (
                  <Tag style={{ marginLeft: 8 }} color="magenta">
                    Admin
                  </Tag>
                )}
                <DownOutlined style={{ marginLeft: 8 }} />
              </div>
            </Popover>
          )}
          <Button
            onClick={() => {
              localStorage.removeItem("accessToken");
              localStorage.removeItem("idToken");
              logout({ logoutParams: { returnTo: window.location.origin } });
            }}
          >
            Log Out
          </Button>
        </div>
      </Row>
    </FrameHeader>
  );
};
