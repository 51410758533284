import React, { useEffect, useState } from "react";
import {
  getApi,
  moneyFormat,
  isSuperAdmin,
  readValue,
  writeValue,
  useWindowSize,
} from "@utils";
import { Table, Space, Input, Select, Radio } from "antd";
import { IShopifyProductType, IConfigType } from "@types";
import { ActionButton, NavButton } from "@components";
import moment from "moment";
const inflection = require("inflection");
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { Typography } from "antd";
import { ModalButton } from "@components/common/ModalButton";
const { Title } = Typography;

interface TableParams {
  pagination?: TablePaginationConfig;
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const Row = ({ children, ...props }: RowProps) => {
  return (
    <tr {...props}>
      {React.Children.map(children, (child) => {
        return child;
      })}
    </tr>
  );
};

export const PayoutsIndex = (props) => {
  const { width } = useWindowSize();
  const [data, setData] = useState<IShopifyProductType[]>([]);
  const [config, setConfig] = useState<IConfigType>();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [prevPage, setPrevPage] = useState("");
  const [nextPage, setNextPage] = useState("");
  const [page, setPage] = useState("");
  const [showSold, setShowSold] = useState(false);
  const [search, setSearch] = useState(readValue("searchQuery"));
  const [consignor, setConsignor] = useState(readValue("searchConsignor"));
  const [status, setStatus] = useState(readValue("payoutStatus"));

  const onPrevPage = () => {
    setPage(prevPage);
  };

  const onNextPage = () => {
    setPage(nextPage);
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        // @ts-ignore
        <a onClick={onPrevPage}>Previous</a>
      );
    }
    if (type === "next") {
      return (
        // @ts-ignore
        <a onClick={onNextPage}>Next</a>
      );
    }
  };

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      position: ["bottomCenter"],
      current: 1,
      pageSize: 25,
      total,
      itemRender,
    },
  });

  const fetchData = async () => {
    setLoading(true);
    const response = await getApi("getPayouts", {
      page: tableParams.pagination.current,
      per_page: tableParams.pagination.pageSize,
      sold: "true",
      search,
      consignor,
      status,
    });
    setLoading(false);
    if (response?.data) {
      setData(response.data);
      setTotal(response.count);
    }
    if (isSuperAdmin()) {
      const configResponse = await getApi("getConfig", {});
      if (response?.data) {
        setConfig(configResponse.data);
      }
    }
  };

  useEffect(() => {
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total,
        itemRender,
      },
    });
  }, [total]);
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(tableParams), search, showSold, consignor, status]);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setTableParams({
      pagination,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const columns: ColumnsType<IShopifyProductType> = [
    {
      title: "Photo",
      dataIndex: "photo",
      render: (photo, record) => {
        return (
          <a
            href={`https://justinreed.com/products/${record.handle}`}
            target="_blank"
          >
            <img src={photo} width={150} style={{ backgroundColor: "#000" }} />
          </a>
        );
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      sorter: false,
      render: (title, record) => {
        return (
          <a
            href={
              isSuperAdmin()
                ? `https://admin.shopify.com/store/justinreednewyork/products/${record.shopify_id}`
                : `https://justinreed.com/products/${record.handle}`
            }
            target="_blank"
          >
            {title}
          </a>
        );
      },
    },
    {
      title: "Consignor",
      dataIndex: "consignor",
      sorter: false,
    },
    {
      title: "Sold At",
      dataIndex: "sold_at",
      sorter: false,
      render: (_, record) => {
        return record?.payout?.sold_at
          ? moment.unix(record?.payout?.sold_at).calendar()
          : "";
      },
    },
    {
      title: "Payable At",
      dataIndex: "payable_at",
      sorter: false,
      render: (_, record) => {
        return record?.payout?.payable_at
          ? moment.unix(record?.payout?.payable_at).calendar()
          : "";
      },
    },
    {
      title: "Listing Price - Discount",
      dataIndex: "price",
      sorter: false,
      render: (_, record) => {
        return [
          moneyFormat(record?.payout?.listing_price),
          moneyFormat(record?.payout?.discount_amount),
        ].join(" - ");
      },
    },
    {
      title: "Sold Price",
      dataIndex: "sold_price",
      sorter: false,
      render: (_, record) => {
        return moneyFormat(record?.payout?.price_paid);
      },
    },
    {
      title: "Commission Amount",
      dataIndex: "commission_amount",
      sorter: false,
      render: (_, record) => {
        return !record.payout?.historic_sale &&
          record?.payout?.commission_amount
          ? [
              moneyFormat(record.payout.commission_amount),
              `(${record?.payout?.commission_percentage * 100}%)`,
            ].join(" ")
          : "---";
      },
    },
    {
      title: "Cashout Amount",
      dataIndex: "cashout_amount",
      sorter: false,
      render: (_, record) => {
        return !record.payout?.historic_sale && record?.payout?.cashout_amount
          ? [
              moneyFormat(record.payout.cashout_amount),
              `(${record?.payout?.earning_percentage * 100 - 2.9}%)`,
            ].join(" ")
          : "---";
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: false,
      render: (_, record) => {
        return record?.payout?.status === "confirmed"
          ? record?.payout?.payable_at > Math.floor(Date.now() / 1000)
            ? "Processed"
            : record?.payout?.historic_sale
            ? "Paid"
            : record?.payout?.redeem_type && !record?.payout?.paid_at
            ? `Redemption Requested: ${inflection.titleize(
                record?.payout?.redeem_type
              )}`
            : record?.payout?.paid_at
            ? `Paid: ${record?.payout?.consignor_notes}`
            : inflection.titleize(record.payout?.status)
          : record?.payout?.redeem_type && !record?.payout?.paid_at
          ? `Redemption Requested: ${inflection.titleize(
              record?.payout?.redeem_type
            )}`
          : record?.payout?.paid_at
          ? `Paid: ${record?.payout?.consignor_notes}`
          : inflection.titleize(record.payout?.status);
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      sorter: false,
      render: (_, record) => {
        return !record.payout?.historic_sale &&
          record.payout?.status === "pending_confirmation" ? (
          <Space>
            <NavButton
              text={"Edit"}
              path={`/payouts/${record.payout.id}/edit`}
            />
            <ActionButton
              endpoint={"confirmPayout"}
              method={"PUT"}
              text={"Confirm Payout"}
              primary={true}
              pathParams={{ id: record.payout?.id }}
              body={{}}
              onComplete={() => {
                location.reload();
              }}
            />
            <ActionButton
              endpoint={"unconfirmPayout"}
              method={"PUT"}
              text={"Unconfirm Payout"}
              pathParams={{ id: record.payout?.id }}
              body={{}}
              onComplete={() => {
                location.reload();
              }}
            />
            <ActionButton
              endpoint={"cancelPayout"}
              method={"DELETE"}
              text={"Cancel Payout"}
              pathParams={{ id: record.payout?.id }}
              body={{}}
              onComplete={() => {
                location.reload();
              }}
            />
          </Space>
        ) : (
          <ActionButton
            endpoint={"cancelPayout"}
            method={"DELETE"}
            text={"Cancel Payout"}
            pathParams={{ id: record.payout?.id }}
            body={{}}
            onComplete={() => {
              location.reload();
            }}
          />
        );
        // record?.payout?.redeem_type && !record?.payout?.paid_at ? (
        //   <ModalButton
        //     endpoint={"markPaidPayout"}
        //     method={"PUT"}
        //     paramKey="consignor_notes"
        //     textarea={true}
        //     title={"Mark as Paid"}
        //     text={"Mark as Paid"}
        //     placeholder={"Consignor Notes"}
        //     pathParams={{ id: record.payout?.id }}
        //     onComplete={() => {
        //       // location.reload();
        //     }}
        //   />
        // ) : null;
      },
    },
  ];

  return (
    <Space
      size="middle"
      direction="vertical"
      style={{ padding: "0 15px", width: "calc(100% - 32px)" }}
    >
      <Input
        size="large"
        defaultValue={search}
        allowClear={true}
        placeholder="Search Payouts"
        onChange={(e) => {
          setSearch(e.target.value);
          writeValue("searchQuery", e.target.value);
        }}
        onPressEnter={() => {
          writeValue("searchQuery", search);
          fetchData();
        }}
      />
      <div>
        {!!total && (
          <Title level={5} style={{ marginTop: 5 }}>
            {total === 1
              ? `1 Result`
              : `${total.toLocaleString("en-US")} Results`}
          </Title>
        )}
        {isSuperAdmin() && config?.sold_consignors && (
          <Select
            showSearch={true}
            allowClear={true}
            style={{ width: 240 }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label?.toLowerCase() ?? "").includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            defaultValue={consignor}
            onChange={(value) => {
              if (typeof value === "string") {
                writeValue("searchConsignor", value);
                setConsignor(value);
              } else {
                writeValue("searchConsignor", "");
                setConsignor("");
              }
            }}
            options={Object.entries(config.sold_consignors).map(
              ([consignor, count]) => ({
                label: `${consignor} (${count})`,
                value: consignor,
              })
            )}
          />
        )}
      </div>
      <div>
        <Radio.Group
          value={status}
          onChange={(e) => {
            setStatus(e.target.value);
            writeValue("payoutStatus", e.target.value);
          }}
        >
          <Radio.Button value="pending_confirmation">
            Pending Confirmation
          </Radio.Button>
          <Radio.Button value="confirmed">Confirmed</Radio.Button>
          <Radio.Button value="redemption_requested">
            Redemption Requested
          </Radio.Button>
          <Radio.Button value="paid">Paid</Radio.Button>
          <Radio.Button value="canceled">Canceled</Radio.Button>
          <Radio.Button value="unconfirmed">Unconfirmed</Radio.Button>
        </Radio.Group>
      </div>
      <div
        style={{
          maxWidth: width > 768 ? "auto" : document.documentElement.clientWidth,
          overflow: "auto",
        }}
      >
        <Table
          columns={columns}
          rowKey={(record) => record.id}
          expandable={{
            defaultExpandedRowKeys: ["0"],
            defaultExpandAllRows: true,
            expandRowByClick: true,
          }}
          locale={{
            emptyText: "No Payouts",
          }}
          components={{
            body: {
              row: Row,
            },
          }}
          dataSource={data}
          pagination={tableParams.pagination}
          loading={loading}
          onChange={handleTableChange}
        />
      </div>
    </Space>
  );
};
