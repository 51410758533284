import React, { useState, useEffect, FunctionComponent } from "react";
import { Layout, Space, Spin } from "antd";
import { putApi, getApi } from "@utils";
import { Header } from "@components";
import { Button, Checkbox, Form, Input } from "antd";
import { InputNumber, Select } from "antd";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { MaskedInput } from "antd-mask-input";
const { Content } = Layout;

interface IFormProps {}

export const EditProfile: FunctionComponent<IFormProps> = ({}) => {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const response = await getApi("getUsers", {}, {});
    setPhoneNumber(response.data.phone_number);
    setFirstName(response.data.first_name);
    setLastName(response.data.last_name);
    setAddressLine1(response.data.address_line_1);
    setAddressLine2(response.data.address_line_2);
    setCity(response.data.city);
    setState(response.data.state);
    setZipCode(response.data.zip_code);
    setLoading(false);
  };

  const onFinish = async (values: any) => {
    console.log("Success:", values);

    const body = {
      phone_number: phoneNumber,
      first_name: firstName,
      last_name: lastName,
      address_line_1: addressLine1,
      address_line_2: addressLine2,
      city,
      state,
      zip_code: zipCode,
    };

    const response = await putApi("putUsers", body, {});
    navigate(response.redirect);
  };

  return (
    <Space
      direction="vertical"
      style={{ width: "100%", backgroundColor: "white" }}
      size={[0, 48]}
    >
      <Header selected="applications" />
      <Content>
        <Row>
          <Col span={20} pull={-2}>
            {loading && <Spin />}
            {!loading && (
              <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 900 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete="off"
              >
                <Form.Item label="First Name" name="firstName">
                  <Input
                    placeholder="First Name"
                    onChange={(e) => setFirstName(e.target.value)}
                    defaultValue={firstName}
                  />
                </Form.Item>
                <Form.Item label="Last Name" name="lastName">
                  <Input
                    placeholder="Last Name"
                    onChange={(e) => setLastName(e.target.value)}
                    defaultValue={lastName}
                  />
                </Form.Item>
                <Form.Item label="Phone Number" name="phoneNumber">
                  <MaskedInput
                    mask={"(000) 000-0000"}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    defaultValue={phoneNumber}
                  />
                </Form.Item>
                <Form.Item label="Address Line 1" name="addressLine1">
                  <Input
                    placeholder="Address Line 1"
                    onChange={(e) => setAddressLine1(e.target.value)}
                    defaultValue={addressLine1}
                  />
                </Form.Item>
                <Form.Item label="Address Line 2" name="addressLine2">
                  <Input
                    placeholder="Address Line 2"
                    onChange={(e) => setAddressLine2(e.target.value)}
                    defaultValue={addressLine2}
                  />
                </Form.Item>
                <Form.Item label="City" name="city">
                  <Input
                    placeholder="City"
                    onChange={(e) => setCity(e.target.value)}
                    defaultValue={city}
                  />
                </Form.Item>
                <Form.Item label="State" name="city">
                  <Select
                    defaultValue={state}
                    showSearch={true}
                    style={{ width: 240 }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label?.toLowerCase() ?? "").includes(
                        input.toLowerCase()
                      )
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    onChange={(value) => {
                      setState(value);
                    }}
                    options={[
                      { label: "Alabama", value: "AL" },
                      { label: "Alaska", value: "AK" },
                      { label: "Alberta", value: "AB" },
                      { label: "American Samoa", value: "AS" },
                      { label: "Arizona", value: "AZ" },
                      { label: "Arkansas", value: "AR" },
                      { label: "Armed Forces (AE)", value: "AE" },
                      { label: "Armed Forces Americas", value: "AA" },
                      { label: "Armed Forces Pacific", value: "AP" },
                      { label: "British Columbia", value: "BC" },
                      { label: "California", value: "CA" },
                      { label: "Colorado", value: "CO" },
                      { label: "Connecticut", value: "CT" },
                      { label: "Delaware", value: "DE" },
                      { label: "District Of Columbia", value: "DC" },
                      { label: "Florida", value: "FL" },
                      { label: "Georgia", value: "GA" },
                      { label: "Guam", value: "GU" },
                      { label: "Hawaii", value: "HI" },
                      { label: "Idaho", value: "ID" },
                      { label: "Illinois", value: "IL" },
                      { label: "Indiana", value: "IN" },
                      { label: "Iowa", value: "IA" },
                      { label: "Kansas", value: "KS" },
                      { label: "Kentucky", value: "KY" },
                      { label: "Louisiana", value: "LA" },
                      { label: "Maine", value: "ME" },
                      { label: "Manitoba", value: "MB" },
                      { label: "Maryland", value: "MD" },
                      { label: "Massachusetts", value: "MA" },
                      { label: "Michigan", value: "MI" },
                      { label: "Minnesota", value: "MN" },
                      { label: "Mississippi", value: "MS" },
                      { label: "Missouri", value: "MO" },
                      { label: "Montana", value: "MT" },
                      { label: "Nebraska", value: "NE" },
                      { label: "Nevada", value: "NV" },
                      { label: "New Brunswick", value: "NB" },
                      { label: "New Hampshire", value: "NH" },
                      { label: "New Jersey", value: "NJ" },
                      { label: "New Mexico", value: "NM" },
                      { label: "New York", value: "NY" },
                      { label: "Newfoundland", value: "NF" },
                      { label: "North Carolina", value: "NC" },
                      { label: "North Dakota", value: "ND" },
                      { label: "Northwest Territories", value: "NT" },
                      { label: "Nova Scotia", value: "NS" },
                      { label: "Nunavut", value: "NU" },
                      { label: "Ohio", value: "OH" },
                      { label: "Oklahoma", value: "OK" },
                      { label: "Ontario", value: "ON" },
                      { label: "Oregon", value: "OR" },
                      { label: "Pennsylvania", value: "PA" },
                      { label: "Prince Edward Island", value: "PE" },
                      { label: "Puerto Rico", value: "PR" },
                      { label: "Quebec", value: "PQ" },
                      { label: "Rhode Island", value: "RI" },
                      { label: "Saskatchewan", value: "SK" },
                      { label: "South Carolina", value: "SC" },
                      { label: "South Dakota", value: "SD" },
                      { label: "Tennessee", value: "TN" },
                      { label: "Texas", value: "TX" },
                      { label: "Utah", value: "UT" },
                      { label: "Vermont", value: "VT" },
                      { label: "Virgin Islands", value: "VI" },
                      { label: "Virginia", value: "VA" },
                      { label: "Washington", value: "WA" },
                      { label: "West Virginia", value: "WV" },
                      { label: "Wisconsin", value: "WI" },
                      { label: "Wyoming", value: "WY" },
                      { label: "Yukon Territory", value: "YT" },
                    ]}
                  />
                </Form.Item>
                <Form.Item label="Zip Code" name="zipCode">
                  <InputNumber
                    placeholder="Zip Code"
                    onChange={(value) => setZipCode(value)}
                    defaultValue={zipCode}
                  />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button type="primary" htmlType="submit">
                    Update
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Col>
        </Row>
      </Content>
    </Space>
  );
};
