import React, { useEffect, useState } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, ConfigProvider, Alert, Space } from "antd";
import { jwtDecode } from "jwt-decode";
import { Header } from "@components";

export const App = ({ children }) => {
  return (
    <Auth0Provider
      domain="justinreed.us.auth0.com"
      clientId="jUTnx041IMaDZyzX79I1oye2hyMbLsHA"
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      cacheLocation="memory"
      authorizationParams={{
        audience: "justinreed",
        scope: "openid profile email offline_access",
        redirect_uri:
          typeof window !== "undefined"
            ? window.location.origin + "/"
            : "https://sell.justinreed.com",
      }}
    >
      <ConfigProvider
        theme={{
          token: {
            // colorPrimary: "#000",
          },
        }}
      >
        <AppWrap children={children} />
      </ConfigProvider>
    </Auth0Provider>
  );
};

export const AppWrap = ({ children }) => {
  const {
    user,
    isLoading,
    error,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();
  const [ready, setReady] = useState(false);
  const [requiresVerification, setRequiresVerification] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    setReady(false);
  }, [user]);

  useEffect(() => {
    if (isAuthenticated) {
      setReady(false);
      (async () => {
        try {
          const token = await getAccessTokenSilently();
          const idToken = await getIdTokenClaims();
          if (idToken && token) {
            const verificationRequired = !(
              // @ts-ignore
              jwtDecode(idToken.__raw).email_verified
            );
            // @ts-ignore
            const userEmail = jwtDecode(idToken.__raw).email;
            localStorage.setItem("accessToken", JSON.stringify(token));
            localStorage.setItem("idToken", JSON.stringify(idToken.__raw));
            localStorage.setItem(
              "emailVerified",
              verificationRequired ? "false" : "true"
            );
            setRequiresVerification(verificationRequired);
            setEmail(userEmail);
          }
          setReady(true);
        } catch (e) {
          if (
            e.error === "missing_refresh_token" ||
            e.error === "invalid_grant"
          ) {
            loginWithRedirect();
          }
        }
      })();
    } else {
      setReady(false);
    }
  }, [isAuthenticated, requiresVerification, window.location.href]);

  return (
    <>
      {isLoading ? null : error ? (
        <div>Oops... {error.message}</div>
      ) : isAuthenticated && ready ? (
        requiresVerification ? (
          <Space
            direction="vertical"
            style={{ width: "100%", backgroundColor: "white" }}
            size={[16, 48]}
          >
            <Header selected="listed_items" />
            <Alert
              style={{ marginLeft: 16, marginRight: 16 }}
              message={`Please check your inbox at ${email} and verify your email before proceeding.`}
              type="error"
            />
          </Space>
        ) : (
          <>{children}</>
        )
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={"https://jrnysell.s3.amazonaws.com/JustinReedType.png"}
            height={35}
            style={{ marginBottom: 40 }}
          />
          <Button
            onClick={() => {
              loginWithRedirect();
            }}
          >
            LOGIN
          </Button>
        </div>
      )}
    </>
  );
};
