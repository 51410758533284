import React, { useEffect, useState } from "react";
import {
  getApi,
  moneyFormat,
  isSuperAdmin,
  readValue,
  writeValue,
  useWindowSize,
} from "@utils";
import { Table, Space, Input, Select } from "antd";
import { IShopifyProductType, IConfigType } from "@types";
import { NavButton, ActionButton } from "@components";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { Typography } from "antd";
const { Title, Text } = Typography;
import { ModalButton } from "@components/common/ModalButton";
import moment from "moment";

interface TableParams {
  pagination?: TablePaginationConfig;
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const Row = ({ children, ...props }: RowProps) => {
  return (
    <tr {...props}>
      {React.Children.map(children, (child) => {
        return child;
      })}
    </tr>
  );
};

export const ProductsIndex = (props) => {
  const { width } = useWindowSize();
  const [data, setData] = useState<IShopifyProductType[]>([]);
  const [config, setConfig] = useState<IConfigType>();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [prevPage, setPrevPage] = useState("");
  const [nextPage, setNextPage] = useState("");
  const [page, setPage] = useState("");
  const [showSold, setShowSold] = useState(false);
  const [search, setSearch] = useState(readValue("searchQuery"));
  const [consignor, setConsignor] = useState(readValue("searchConsignor"));

  const onPrevPage = () => {
    setPage(prevPage);
  };

  const onNextPage = () => {
    setPage(nextPage);
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        // @ts-ignore
        <a onClick={onPrevPage}>Previous</a>
      );
    }
    if (type === "next") {
      return (
        // @ts-ignore
        <a onClick={onNextPage}>Next</a>
      );
    }
  };

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      position: ["bottomCenter"],
      current: 1,
      pageSize: 25,
      total,
      itemRender,
    },
  });

  const fetchData = async () => {
    setLoading(true);
    const response = await getApi("getProducts", {
      page: tableParams.pagination.current,
      per_page: tableParams.pagination.pageSize,
      sold: showSold ? "true" : "false",
      search,
      consignor,
    });
    setLoading(false);
    if (response?.data) {
      setData(response.data);
      setTotal(response.count);
    }
    if (isSuperAdmin()) {
      const configResponse = await getApi("getConfig", {});
      if (response?.data) {
        setConfig(configResponse.data);
      }
    }
  };

  useEffect(() => {
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total,
        itemRender,
      },
    });
  }, [total]);
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(tableParams), search, showSold, consignor]);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setTableParams({
      pagination,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };
  const columns: ColumnsType<IShopifyProductType> = [
    {
      title: "Photo",
      dataIndex: "photo",
      render: (photo, record) => {
        return (
          <a
            href={`https://justinreed.com/products/${record.handle}`}
            target="_blank"
          >
            <img src={photo} width={150} style={{ backgroundColor: "#000" }} />
          </a>
        );
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      sorter: false,
      render: (title, record) => {
        return (
          <a
            href={
              isSuperAdmin()
                ? `https://admin.shopify.com/store/justinreednewyork/products/${record.shopify_id}`
                : `https://justinreed.com/products/${record.handle}`
            }
            target="_blank"
          >
            {title}
          </a>
        );
      },
    },
    {
      title: "Sizes",
      dataIndex: "size",
      sorter: false,
      render: (size) => {
        return size == "Default Title" ? "OS" : size;
      },
    },
    ...(isSuperAdmin()
      ? [
          {
            title: "Consignor",
            dataIndex: "consignor",
            sorter: false,
          },
        ]
      : []),
    {
      title: "Brand",
      dataIndex: "vendor",
      sorter: false,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: false,
      render: (status) => {
        return status == "active" ? "Active" : status;
      },
    },
    {
      title: "Days Listed",
      dataIndex: "published_at",
      sorter: false,
      render: (publishedAt) => {
        if (publishedAt) {
          return (
            <p>
              {Math.ceil(
                moment.duration(moment().diff(moment(publishedAt))).asDays()
              )}{" "}
              Days
              <br />
              <Text type="secondary">
                {moment(publishedAt).format("MMMM Do, YYYY")}
              </Text>
            </p>
          );
        }
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      sorter: false,
      render: (price) => {
        return moneyFormat(price);
      },
    },
  ];

  if (isSuperAdmin()) {
    columns.push({
      title: "Action",
      dataIndex: "action",
      sorter: false,
      render: (_, record) => {
        return (
          <Space>
            <NavButton
              text={"Create Payout"}
              path={`/payouts/new/${record.id}`}
            />
            <ActionButton
              endpoint={"putProductMarkReturned"}
              method={"PUT"}
              text={"Mark as Returned"}
              pathParams={{}}
              body={{ product_id: record?.id }}
              onComplete={() => {
                location.reload();
              }}
            />
            <>
              {!!record.shopify_variants?.length &&
                record.shopify_variants.map((variant) => (
                  <ModalButton
                    endpoint={"postProductPriceChange"}
                    method={"POST"}
                    paramKey="new_price"
                    params={{
                      product_id: record.id,
                      variant_id: variant.id,
                    }}
                    content={
                      <div>
                        <img
                          src={record.photo}
                          width={150}
                          style={{ marginBottom: 5 }}
                        />
                        <p>
                          {record.title} /{" "}
                          {variant.title == "Default Title"
                            ? "OS"
                            : variant.title}{" "}
                          / {record.vendor}
                        </p>
                      </div>
                    }
                    defaultSliderValue={parseFloat(variant.price)}
                    textarea={false}
                    title={"Change Listing Price"}
                    text={`Update${
                      record.shopify_variants.length > 1
                        ? variant.title == "Default Title"
                          ? ""
                          : ` ${variant.title}`
                        : ""
                    } Price`}
                    slider={true}
                    pathParams={{}}
                    onComplete={() => {
                      // location.reload();
                    }}
                  />
                ))}
            </>
          </Space>
        );
      },
    });
    columns.push({
      title: "ID",
      dataIndex: "id",
      sorter: false,
      render: (id) => {
        return id;
      },
    });
  } else {
    columns.push({
      title: "Action",
      dataIndex: "action",
      sorter: false,
      render: (_, record) => {
        return (
          <Space>
            {!!record.shopify_variants?.length &&
              record.shopify_variants.map((variant) => (
                <ModalButton
                  endpoint={"postProductPriceChange"}
                  method={"POST"}
                  paramKey="new_price"
                  params={{
                    product_id: record.id,
                    variant_id: variant.id,
                  }}
                  content={
                    <div>
                      <img
                        src={record.photo}
                        width={150}
                        style={{ marginBottom: 5 }}
                      />
                      <p>
                        {record.title} /{" "}
                        {variant.title == "Default Title"
                          ? "OS"
                          : variant.title}{" "}
                        / {record.vendor}
                      </p>
                    </div>
                  }
                  defaultSliderValue={parseFloat(variant.price)}
                  textarea={false}
                  title={"Change Listing Price"}
                  text={`Update${
                    record.shopify_variants.length > 1
                      ? variant.title == "Default Title"
                        ? ""
                        : ` ${variant.title}`
                      : ""
                  } Price`}
                  slider={true}
                  pathParams={{}}
                  onComplete={() => {
                    // location.reload();
                  }}
                />
              ))}
          </Space>
        );
      },
    });
  }

  return (
    <Space
      size="middle"
      direction="vertical"
      style={{ padding: "0 15px", width: "calc(100% - 32px)" }}
    >
      <Input
        size="large"
        allowClear={true}
        defaultValue={search}
        placeholder="Search Your Consignments"
        onChange={(e) => {
          writeValue("searchQuery", e.target.value);
          setSearch(e.target.value);
        }}
        onPressEnter={() => {
          writeValue("searchQuery", search);
          fetchData();
        }}
      />
      <div>
        {!!total && (
          <Title level={5} style={{ marginTop: 5 }}>
            {total === 1
              ? `1 Result`
              : `${total.toLocaleString("en-US")} Results`}
          </Title>
        )}
        {isSuperAdmin() && config?.consignors && (
          <Select
            showSearch={true}
            allowClear={true}
            style={{ width: 240 }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label?.toLowerCase() ?? "").includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            defaultValue={consignor}
            onChange={(value) => {
              if (typeof value === "string") {
                writeValue("searchConsignor", value);
                setConsignor(value);
              } else {
                writeValue("searchConsignor", "");
                setConsignor("");
              }
            }}
            options={Object.entries(config.consignors).map(
              ([consignor, count]) => ({
                label: `${consignor} (${count})`,
                value: consignor,
              })
            )}
          />
        )}
      </div>
      <div
        style={{
          maxWidth: width > 768 ? "auto" : document.documentElement.clientWidth,
          overflow: "auto",
        }}
      >
        <Table
          columns={columns}
          rowKey={(record) => record.id}
          expandable={{
            defaultExpandedRowKeys: ["0"],
            defaultExpandAllRows: true,
            expandRowByClick: true,
          }}
          locale={{
            emptyText: "No Listed Items",
          }}
          components={{
            body: {
              row: Row,
            },
          }}
          dataSource={data}
          pagination={tableParams.pagination}
          loading={loading}
          onChange={handleTableChange}
        />
      </div>
    </Space>
  );
};
