import React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  Routes,
  BrowserRouter,
  Route,
} from "react-router-dom";
import { App, Home, EditProfile, Sold, Payouts, PayoutsNew } from "@components";

const router = [
  {
    path: "/",
    Component: Home,
  },
  {
    path: "/edit-profile",
    Component: EditProfile,
  },
  {
    path: "/sold",
    Component: Sold,
  },
  {
    path: "/payouts",
    Component: Payouts,
  },
  {
    path: "/payouts/new/:shopify_product_id",
    Component: PayoutsNew,
  },
  {
    path: "/payouts/:id/edit",
    Component: PayoutsNew,
  },
];

// @ts-ignore
window.initApp = () => {
  createRoot(document.body.appendChild(document.createElement("div"))).render(
    <BrowserRouter>
      <App>
        <Routes>
          {router.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              Component={route.Component}
            />
          ))}
        </Routes>
      </App>
    </BrowserRouter>
  );
};
