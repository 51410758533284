import React from "react";
import { Layout, Space } from "antd";
const { Content } = Layout;
import { Header, ProductsIndex } from "@components";

export const Home = () => {
  return (
    <Space
      direction="vertical"
      style={{ width: "100%", backgroundColor: "white" }}
      size={[0, 48]}
    >
      <Content>
        <Header selected="listed_items" />
        <ProductsIndex />
      </Content>
    </Space>
  );
};
