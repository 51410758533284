import React, { FunctionComponent, useState } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

interface INavButtonProps {
  path: string;
  text: string;
}

export const NavButton: FunctionComponent<INavButtonProps> = ({
  path,
  text,
}) => {
  const navigate = useNavigate();
  return (
    <Button
      type="link"
      onClick={() => {
        navigate(path);
      }}
    >
      {text}
    </Button>
  );
};
