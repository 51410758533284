import React, { ReactNode, FunctionComponent, useState } from "react";
import { putApi, postApi, moneyFormat, CEndpoints } from "@utils";
import { TMethod } from "@types";
import { Button, Modal, Alert } from "antd";
import { useNavigate } from "react-router-dom";
import { Input } from "antd";
const { TextArea } = Input;
import { Slider } from "antd";
import type { SliderSingleProps } from "antd";

interface IActionButtonProps {
  method: TMethod;
  endpoint: keyof typeof CEndpoints;
  text: string;
  title: string;
  params?: Record<any, any>;
  paramKey?: string;
  placeholder?: string;
  textarea?: boolean;
  slider?: boolean;
  content?: ReactNode;
  defaultSliderValue?: number;
  pathParams: Record<any, any>;
  onComplete?: (payload?: Record<any, any>) => void;
}

export const ModalButton: FunctionComponent<IActionButtonProps> = ({
  method,
  endpoint,
  text,
  title,
  textarea,
  placeholder,
  paramKey,
  pathParams,
  onComplete,
  params,
  content,
  slider,
  defaultSliderValue,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [value, setValue] = useState(slider ? defaultSliderValue || 0 : "");
  const [message, setMessage] = useState("");
  const [sliderValue, setSliderValue] = useState(defaultSliderValue || 0);
  const onClick = async () => {
    setActive(true);
  };
  // @ts-ignore
  const min = Math.ceil(parseInt(defaultSliderValue * 0.7, 10) / 10) * 10;
  console.log({ defaultSliderValue, min });
  const marks: SliderSingleProps["marks"] = {
    [min]: {
      label: <strong>{moneyFormat(min)}</strong>,
    },
    [defaultSliderValue]: {
      label: <strong>{moneyFormat(defaultSliderValue)}</strong>,
    },
  };
  const onSubmit = async () => {
    if (method === "POST") {
      setLoading(true);
      const response = await postApi(
        endpoint,
        paramKey && textarea
          ? { [paramKey]: value }
          : params
          ? { ...params, ...(paramKey ? { [paramKey]: value } : {}) }
          : {},
        pathParams
      );
      onComplete && onComplete(response.data);
      setLoading(false);
      if (textarea) {
        setActive(false);
        navigate(response.redirect);
      } else {
        setMessage(response?.data?.message);
      }
    }
    if (method === "PUT") {
      setLoading(true);
      const response = await putApi(
        endpoint,
        paramKey && textarea
          ? { [paramKey]: value }
          : params
          ? { ...params, ...(paramKey ? { [paramKey]: value } : {}) }
          : {},
        pathParams
      );
      onComplete && onComplete(response.data);
      setLoading(false);
      if (textarea) {
        setActive(false);
        navigate(response.redirect);
      } else {
        setMessage(response?.data?.message);
      }
    }
  };
  return (
    <>
      <Modal
        title={title}
        open={active}
        okText={
          text +
          (value !== defaultSliderValue
            ? // @ts-ignore
              ` to ${moneyFormat(parseInt(value, 10))}`
            : "")
        }
        okButtonProps={{
          disabled: loading || value === defaultSliderValue,
          loading,
        }}
        onOk={() => {
          onSubmit();
        }}
        onCancel={() => {
          setActive(false);
        }}
      >
        <div style={{ padding: 10 }}>
          {textarea && (
            <TextArea
              rows={4}
              placeholder={placeholder}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
          )}
          {content}
          {!!message && <Alert message={message} />}
          {slider && (
            <Slider
              min={min}
              max={defaultSliderValue}
              marks={marks}
              step={10}
              defaultValue={defaultSliderValue}
              onChange={(v) => setValue(v)}
              tooltip={{
                formatter: (value) => moneyFormat(value),
                open: active,
              }}
            />
          )}
        </div>
      </Modal>
      <Button onClick={onClick}>{text}</Button>
    </>
  );
};
