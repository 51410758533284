import React, { useState, useEffect, FunctionComponent } from "react";
import { Layout, Space, Spin } from "antd";
import { putApi, getApi, postApi, moneyFormat } from "@utils";
import { Button, Checkbox, Form, Input } from "antd";
import { Cascader, InputNumber, Select } from "antd";
import { Typography } from "antd";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
const { Content } = Layout;
const { Title, Text } = Typography;
import customParseFormat from "dayjs/plugin/customParseFormat";
import type { DatePickerProps } from "antd";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import moment from "moment";
dayjs.extend(customParseFormat);

import { IShopifyProductType } from "@types";

interface IAppBannersFormProps {
  id?: string;
  shopify_product_id?: string;
}

export const PayoutsForm: FunctionComponent<IAppBannersFormProps> = ({
  id,
  shopify_product_id,
}) => {
  const navigate = useNavigate();
  const [commissionPercentage, setCommissionPercentage] = useState(0.0);
  const [earningPercentage, setEarningPercentage] = useState(0.0);
  const [earningAmount, setEarningAmount] = useState(0.0);
  const [commissionAmount, setCommissionAmount] = useState(0.0);
  const [discountAmount, setDiscountAmount] = useState(0.0);
  const [listingPrice, setListingPrice] = useState(0.0);
  const [soldPrice, setSoldPrice] = useState(0.0);
  const [loading, setLoading] = useState(false);
  const [soldAt, setSoldAt] = useState(moment().format("YYYY-MM-DD"));
  const [data, setData] = useState<IShopifyProductType>();

  useEffect(() => {
    fetchData();
  }, []);

  const getCommissionPercentage = (priceSold: number) => {
    if (priceSold <= 300) {
      return 0.4;
    } else if (priceSold > 300 && priceSold < 2500) {
      return 0.3;
    } else {
      return 0.2;
    }
  };

  const fetchData = async () => {
    setLoading(true);
    const response: {
      data: IShopifyProductType;
    } = await getApi(
      "getPayout",
      id ? { type: "payout" } : { type: "shopify_product" },
      id ? { id } : { id: shopify_product_id }
    );
    setData(response.data);
    const localListingPrice =
      response.data.payout?.listing_price || response.data.price;
    setListingPrice(localListingPrice);
    setDiscountAmount(response.data.payout?.discount_amount || 0.0);
    const localSoldPrice =
      localListingPrice - (response.data.payout?.discount_amount || 0.0);
    const localCommissionPercentage =
      (response.data.payout?.commission_percentage ||
        getCommissionPercentage(localSoldPrice)) * 100;
    const localEarningPercentage = 100.0 - localCommissionPercentage;
    setCommissionPercentage(localCommissionPercentage);

    setSoldPrice(localSoldPrice);
    setEarningPercentage(localEarningPercentage);
    setCommissionAmount(localSoldPrice * (localCommissionPercentage / 100));
    setEarningAmount(localSoldPrice * (localEarningPercentage / 100));
    setLoading(false);
  };

  const onFinish = async (values: any) => {
    console.log("Success:", values);

    const body = {
      listing_price: listingPrice,
      commission_percentage: commissionPercentage,
      discount_amount: discountAmount,
      shopify_product_id,
      sold_at: soldAt,
    };

    const response = id
      ? await putApi("editPayout", body, { id })
      : await postApi("createPayouts", body, {});
    navigate(response.redirect);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <Space
      direction="vertical"
      style={{ width: "100%", backgroundColor: "white", padding: "15px" }}
      size={[0, 48]}
    >
      <Content>
        <Row>
          <Col span={20} pull={-2}>
            {loading && <Spin />}
            {!loading && (
              <>
                <Title level={5} style={{ marginTop: 5 }}>
                  {id ? "Edit Payout" : "Create Payout"}
                </Title>
                <Form
                  name="basic"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  style={{ maxWidth: 900 }}
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item label="Product Title" name="productTitle">
                    {data?.title}
                  </Form.Item>
                  <Form.Item label="Product Image" name="productImage">
                    {data?.photo ? <img src={data.photo} width={120} /> : null}
                  </Form.Item>
                  <Form.Item label="Consignor" name="consignor">
                    {data?.consignor}
                  </Form.Item>
                  {shopify_product_id && (
                    <Form.Item label="Sold At" name="soldAt">
                      <DatePicker
                        defaultValue={dayjs(soldAt, "YYYY-MM-DD")}
                        onChange={(_data, dateString) => {
                          setSoldAt(dateString);
                        }}
                        format={["YYYY-MM-DD"]}
                      />
                    </Form.Item>
                  )}
                  <Form.Item label="Listing Price" name="listingPrice">
                    <InputNumber
                      placeholder="listingPrice"
                      onChange={(value) => {
                        setListingPrice(value);
                        const localSoldPrice = value - discountAmount;
                        setSoldPrice(localSoldPrice);
                        setEarningAmount(
                          localSoldPrice * (earningPercentage / 100)
                        );
                        setCommissionAmount(
                          localSoldPrice * (commissionPercentage / 100)
                        );
                      }}
                      formatter={(value) =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                      defaultValue={listingPrice}
                    />
                  </Form.Item>
                  <Form.Item label="Discount Amount" name="discountAmount">
                    <InputNumber
                      placeholder="Discount Amount"
                      onChange={(value) => {
                        setDiscountAmount(value);
                        const localSoldPrice = listingPrice - value;
                        setSoldPrice(localSoldPrice);
                        setEarningAmount(
                          localSoldPrice * (earningPercentage / 100)
                        );
                        setCommissionAmount(
                          localSoldPrice * (commissionPercentage / 100)
                        );
                      }}
                      formatter={(value) =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                      defaultValue={discountAmount}
                    />
                  </Form.Item>
                  {soldPrice > 0.0 && (
                    <Form.Item label="Sold Price" name="soldPrice">
                      ${soldPrice}
                    </Form.Item>
                  )}
                  <Form.Item
                    label="Commission Percentage"
                    name="commission_percentage"
                  >
                    <InputNumber
                      placeholder="Commission Percentage"
                      onChange={(value) => {
                        setCommissionPercentage(value);
                        setEarningPercentage(100.0 - value);
                        setEarningAmount(soldPrice * ((100 - value) / 100));
                        setCommissionAmount(soldPrice * (value / 100));
                      }}
                      min={0}
                      max={100}
                      formatter={(value) => `${value}%`}
                      parser={(value) => value!.replace("%", "")}
                      defaultValue={commissionPercentage}
                    />
                  </Form.Item>
                  {earningPercentage > 0.0 && (
                    <Form.Item
                      label="Earning Percentage"
                      name="earningPercentage"
                    >
                      {earningPercentage}%
                    </Form.Item>
                  )}
                  {commissionAmount > 0.0 && (
                    <Form.Item
                      label="Store Commission Amount"
                      name="commissionAmount"
                    >
                      {moneyFormat(commissionAmount)}
                    </Form.Item>
                  )}
                  {earningAmount > 0.0 && (
                    <Form.Item
                      label="Consignor Earning Amount"
                      name="earningAmount"
                    >
                      {moneyFormat(earningAmount)}
                    </Form.Item>
                  )}
                  <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                      {id ? "Edit" : "Create"}
                    </Button>
                  </Form.Item>
                </Form>
              </>
            )}
          </Col>
        </Row>
      </Content>
    </Space>
  );
};
