// @ts-nocheck

import { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { IIdToken } from "@types";

interface IWindowResize {
  width: number;
  height: number;
  isPortrait: boolean;
}

export const moneyFormat = (price: number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(price);
};

export const readValue = (key: string) => {
  return JSON.parse(localStorage.getItem(key));
};

export const deleteValue = (key: string) => {
  return localStorage.removeItem(key);
};

export const writeValue = (key: string, value: any) => {
  return localStorage.setItem(key, JSON.stringify(value));
};

export const CEndpoints = {
  getProducts: "/api/v1/products",
  postProductPriceChange: "/api/v1/products/price_change",
  putProductMarkReturned: "/api/v1/products/mark_returned",
  getConfig: "/api/v1/config",
  getUsers: "/api/v1/users",
  putUsers: "/api/v1/users",
  getPayouts: "/api/v1/payouts",
  createPayouts: "/api/v1/payouts",
  confirmPayout: "/api/v1/payouts/:id/confirm",
  getPayout: "/api/v1/payouts/:id",
  editPayout: "/api/v1/payouts/:id/edit",
  redeemPayout: "/api/v1/payouts/:id/redeem",
  markPaidPayout: "/api/v1/payouts/:id/pay",
  cancelPayout: "/api/v1/payouts/:id/cancel",
  unconfirmPayout: "/api/v1/payouts/:id/unconfirm",
};

export const isSuperAdmin = () => {
  const idToken = JSON.parse(localStorage.getItem("idToken"));
  if (!idToken) {
    return false;
  }
  const idData: IIdToken = jwtDecode(idToken);
  // @ts-ignore
  return (
    (idData.email === "manminhhoang@gmail.com" ||
      idData.email.includes("@justinreed.com")) &&
    idData.email !== "shop@justinreed.com"
  );
};

export const getApi = async (
  endpointPath: keyof typeof CEndpoints,
  queryParams?: Record<any, any>,
  pathParams?: Record<any, any>
) => {
  const append = `?${new URLSearchParams(queryParams)}`;
  const response = await fetch(endpoint(endpointPath, pathParams) + append, {
    method: "GET",
    headers: getHeaders(),
  });
  if (response.redirected) {
    window.location.href = response.url;
  }
  const json = await response.json();
  return json;
};

export const postApi = async (
  endpointPath: keyof typeof CEndpoints,
  body?: Record<any, any>,
  pathParams?: Record<any, any>,
  rawBody?: boolean
) => {
  const response = await fetch(endpoint(endpointPath, pathParams), {
    method: "POST",
    headers: getHeaders(!rawBody),
    body: JSON.stringify(body),
  });
  if (response.redirected) {
    window.location.href = response.url;
  }
  const json = await response.json();
  return json;
};

export const putApi = async (
  endpointPath: keyof typeof CEndpoints,
  body?: Record<any, any>,
  pathParams?: Record<any, any>,
  rawBody?: boolean
) => {
  const response = await fetch(endpoint(endpointPath, pathParams), {
    method: "PUT",
    headers: getHeaders(!rawBody),
    body: JSON.stringify(body),
  });
  if (response.redirected) {
    window.location.href = response.url;
  }
  const json = await response.json();
  return json;
};

export const deleteApi = async (
  endpointPath: keyof typeof CEndpoints,
  pathParams: Record<any, any>
) => {
  const response = await fetch(endpoint(endpointPath, pathParams), {
    method: "DELETE",
    headers: getHeaders(),
  });
  if (response.redirected) {
    window.location.href = response.url;
  }
  const json = await response.json();
  return json;
};

const endpoint = (
  endpoint: keyof typeof CEndpoints,
  pathParams?: Record<string, string>
) => {
  let endpointUri: string = CEndpoints[endpoint];
  if (pathParams) {
    Object.entries(pathParams).forEach(([key, value]) => {
      endpointUri = endpointUri.replace(`:${key}`, value);
    });
  }
  return endpointUri;
};

const getHeaders = (json = true) => {
  const baseHeader = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("accessToken"))}`,
    "X-Id-Token": JSON.parse(localStorage.getItem("idToken")),
  };
  return json
    ? { ...baseHeader, "Content-Type": "application/json" }
    : { ...baseHeader };
};

export const useWindowSize = () => {
  const isPxlDense = window.devicePixelRatio > 1;
  const isTouch = "ontouchstart" in document.documentElement;
  const isPortrait = window.innerHeight > window.innerWidth;
  const [windowSize, setWindowSize] = useState<IWindowResize>({
    width: window.innerWidth,
    height: window.innerHeight,
    isPortrait: false,
  });

  useEffect(() => {
    function handleResize() {
      clearTimeout(window.resizedFinished);
      window.resizedFinished = setTimeout(function () {
        window.resizing = false;
      }, 500);
      window.resizing = true;
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isPortrait: isPortrait && isPxlDense && isTouch,
      });
    }
    function handleOrientation(m: MediaQueryListEvent) {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isPortrait: m.matches && isPxlDense && isTouch,
      });
    }

    window.addEventListener("resize", handleResize);
    window.matchMedia("(orientation: portrait)").addListener(handleOrientation);

    handleResize();

    return () => {
      window
        .matchMedia("(orientation: portrait)")
        .removeListener(handleOrientation);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    function handleResize() {
      clearTimeout(window.resizedFinished);
      window.resizedFinished = setTimeout(function () {
        window.resizing = false;
      }, 500);
      window.resizing = true;
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isPortrait: isPortrait && isPxlDense && isTouch,
      });
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};
